/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Modal } from 'react-bootstrap';
import { KTIcon } from '../../../../../../_metronic/helpers';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToastMessageSuccess, showToastMessageError } from '../../../../../modules/Toast-Message/TosatMessage';

const modalsRoot = document.getElementById('root-modals') || document.body;

const StatementReceipt = ({ show, handleClose, statementId }) => {
    
    const [fetchCount,setFetchCount] = useState(true);
    const [ReceiptUrl,setReceiptUrl] = useState([]);
    const [loading,setLoading] = useState(true);


    const setModalClose = ()=>{
        setFetchCount(true);
        handleClose();
    }

    const setStatementReceiptUrl = (stmtid) =>{
        // const path = `http://localhost:8000/storage/invoices/statement_invoice_${stmtid}.pdf`;
        const path = `https://erp.makemoneyormiles.com/storage/invoices/statement_invoice_${stmtid}.pdf`;
        setReceiptUrl(path);
    }
 
    useEffect(() => {
        if(fetchCount == true){
            setStatementReceiptUrl(statementId);
            setFetchCount(false);
            setLoading(false);
        }
    }, [statementId]);

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered modal-xl'
            show={show}
            onHide={setModalClose}
            backdrop={true}
        >
            <div className='modal-header pb-0' style={{backgroundColor:'rgb(50,54,57)'}}>
                <h2 className='text-white'>Receipt : {statementId}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={setModalClose}>
                    {/* <KTIcon className='fs-1 text-white' iconName='cross' /> */}
                    <i className='fas fa-close text-white'></i>
                </div>
            </div>

            <div className='card'>
                <div className='card-body d-flex pt-0 p-0'>
                {/* <div style={{ height: '80vh', overflow: 'auto' }}> */}
                    <iframe
                    src={ReceiptUrl}
                    style={{ width: '100%', height: '80vh' }}
                    frameBorder={0}
                    title="PDF Viewer"
                    ></iframe>
                {/* </div> */}
                </div>
            </div>
        </Modal>,
        modalsRoot
    );
}

export {StatementReceipt };
