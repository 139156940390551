/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { createPortal } from 'react-dom';
import { Modal } from 'react-bootstrap';
import { KTIcon } from '../../../_metronic/helpers';
import CurrencyFormat from 'react-currency-format';

const modalsRoot = document.getElementById('root-modals') || document.body;

const ReturnDetails = ({ show, handleClose, detailsData }) => {
    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={show}
            onHide={() => {
                handleClose();
            }}
            backdrop={true}
        >
            <div className='modal-header pb-0'>
                <h2>Details</h2>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => {
                    handleClose();
                }}>
                    <KTIcon className='fs-1' iconName='cross' />
                </div>
            </div>

            <div className='card'>
                <div className='card-body'>
                    <div className="row">
                        <div className="col-4">
                            <img src={detailsData.image} style={{ height: "200px", width: "100%" }} />
                        </div>
                        <div className="col-8">
                            <span className='fs-4 text-gray-700 text-hover-primary'>
                                <strong>{detailsData.name}</strong>
                            </span>
                            <div className='d-flex justify-content-between mt-5'>
                                <div className='fs-5 fw-bold symbol-label'>
                                    Commit No:
                                </div>
                                <div className='fs-5 text-gray-800 pe-1'>{detailsData.commit_no}</div>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div className='fs-5 fw-bold symbol-label'>
                                    Tracking No:
                                </div>
                                <div className='fs-5 text-gray-800 pe-1'>{detailsData.tracking_id}</div>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div className='fs-5 fw-bold symbol-label'>
                                    Date:
                                </div>
                                <div className='fs-5 text-gray-800 pe-1'>{detailsData.date}</div>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div className='fs-5 fw-bold symbol-label'>
                                    Price:
                                </div>
                                <div className='fs-5 text-gray-800 pe-1'><CurrencyFormat decimalScale={2} value={parseFloat(detailsData.price).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></div>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div className='fs-5 fw-bold symbol-label'>
                                    Status:
                                </div>
                                <div className='text-align-end'>
                                    {detailsData.status == 0 && (
                                        <span className='badge badge-light-success fs-8 fw-bold success_active'>Completed</span>
                                    )}
                                    {detailsData.status == 1 && (
                                        <span className='badge badge-light-warning fs-8 fw-bold warning_active'>Pending</span>
                                    )}
                                </div>
                            </div>
                            <div className='row justify-content-between'>
                                <div className='col-4 fs-5 fw-bold symbol-label'>
                                    Message:
                                </div>
                                <div className='col-8 fs-5 text-gray-800 pe-1'>{detailsData.message}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>,
        modalsRoot
    );
}

export { ReturnDetails };
