import {useEffect, useState, useRef} from 'react'
import {AddCommits} from './AddCommits'
import {Details} from './Details'
import axios from 'axios'
import CurrencyFormat from 'react-currency-format'
import Loader from 'react-js-loader'
import '../Style.css'
import RangeSlider from 'react-range-slider-input'
import 'react-range-slider-input/dist/style.css'
import {Dropdown} from 'react-bootstrap'
import { AddTracking } from './AddTracking'

const Deals = () => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false)
  const [showCreateAppModal2, setShowCreateAppModal2] = useState(false)
  const [showCreateAppModalTracking, setShowCreateAppModalTracking] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState("All")

  const [apiData, setApiData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [propsData, setPropsData] = useState([])
  const [loading, setLoading] = useState(false)
  const [changedColor, setChangedColor] = useState({})

  const [hotDealExist, setHotDealExist] = useState(false)

  const [sliderValues, setSliderValues] = useState({from: 0, to: 100000})

  const API_URL = process.env.REACT_APP_API_URL
  const DEALS_URL = `${API_URL}/deals`
  const ALL_DEALS_URL = `${API_URL}/deals-all`

  const userDetails = localStorage.getItem('kt-auth-react-v');
  const myUser = JSON.parse(userDetails);
  
  const getApiData = async () => {
    setLoading(true)
    try {
      await axios
        .post(ALL_DEALS_URL,{user_id:myUser.id})
        .then((res) => {
          setApiData(res.data.data)
          if(res.data.data.filter(item => item.hotdeal == 1).length > 0){
            setHotDealExist(true);
          }
          setLoading(false)
          console.log(res)
        })
        .catch((error) => {
          console.error(error)
          setLoading(false)
        })
    } catch (error) {
      console.error(error)
    }
  }
  // const getApiData = async () => {
  //   setLoading(true)
  //   try {
  //     await axios
  //       .get(DEALS_URL)
  //       .then((res) => {
  //         setApiData(res.data.data)
  //         if(res.data.data.filter(item => item.hotdeal == 1).length > 0){
  //           setHotDealExist(true);
  //         }
  //         setLoading(false)
  //         console.log(res)
  //       })
  //       .catch((error) => {
  //         console.error(error)
  //         setLoading(false)
  //       })
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }

  const handleSliderChange = (e) => {
    setSliderValues(() => ({
      from: e[0],
      to: e[1],
    }))
  }

  const onRetailData = () => {
    setFilteredData(
      apiData.filter((items) => {
        return items.retail == 1;
      })
    )
  }

  const belowCostData = () => {
    setFilteredData(
      apiData.filter((items) => {
        return items.below_cost == 1
      })
    )
  }

  const hotDealsData = () => {
    setFilteredData(
      apiData.filter((items) => {
        return items.hotdeal == 1
      })
    )
  }

  const onCommissionData = () => {
    setFilteredData(
      apiData.filter((items) => {
        return items.commission_status == 1
      })
    )
  }

  const allActiveData = () => {
    setFilteredData(apiData)
  }

  const itemPrice = (val) => {
    var data = {}
    if (val.commission_status == 1) {
      const commission = parseFloat(val.default_color?.commission)
      data = {
        status: 1,
        orderPrice: (parseFloat(val.default_color?.price) + commission).toFixed(2),
        actualPrice: parseFloat(val.default_color?.price).toFixed(2),
        commission: commission,
      }
    } else if (val.retail == 1) {
      data = {
        status: 0,
        orderPrice: parseFloat(val.default_color?.price).toFixed(2),
        actualPrice: parseFloat(val.default_color?.price).toFixed(2),
        commission: 0,
      }
    } else if (val.below_cost == 1) {
      data = {
        status: 1,
        orderPrice: parseFloat(val.default_color?.price).toFixed(2),
        actualPrice: parseFloat(val.default_color?.commission).toFixed(2),
        commission: 0,
      }
    }

    return data
  }

  useEffect(() => {
    const filterOnSale = apiData.filter((items) => {
      return items.on_sale_now == 1
    })
    setFilteredData(filterOnSale)
  }, [apiData])

  useEffect(() => {
    getApiData()
  }, [])

  const handleSelect = (eventKey) => {
    setSelectedFilter(eventKey)
    if(eventKey == "Expired"){
      setFilteredData(
        apiData.filter((items) => {
          return items.status == 2
        })
      )
    } else if(eventKey == "In Stock") {
      setFilteredData(
        apiData.filter((items) => {
          return items.status == 1 && items.deal_stock == 'true'
        })
      )
    } else if(eventKey == "Commitment Full") {
      setFilteredData(
        apiData.filter((items) => {
          return items.deal_stock == 'false' && items.status !=2 ;
        })
      )
    } else if(eventKey == "Retail") {
      setFilteredData(
        apiData.filter((items) => {
          return items.retail == 1 ;
        })
      )
    } else if(eventKey == "Commission") {
      setFilteredData(
        apiData.filter((items) => {
          return items.commission_status == 1 ;
        })
      )
    } else if(eventKey == "Below Cost") {
      setFilteredData(
        apiData.filter((items) => {
          return items.below_cost == 1;
        })
      )
    } else {
      const filterOnSale = apiData.filter((items) => {
        return items.on_sale_now == 1
      })
      setFilteredData(filterOnSale)
    }
    } 

    const formatDate = (myDate) =>{
      const date = new Date(myDate);
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();
    
      return `${month}-${day}-${year}`;
    }

    const getDealLabel = (item) =>{
      if(item.below_cost == 1){
        return(
        <span className="fs-7 py-1 px-2" style={{border:'1px solid #CC0202',color:'#CC0202',borderRadius:8,backgroundColor:'white'}}>Below Cost</span>
        )
      }
      if(item.commission_status == 1){
        return(
        <span className="fs-7 py-1 px-2" style={{border:'1px solid #7dbc00',color:'#7dbc00',borderRadius:8,backgroundColor:'white'}}>Commission</span>
        )
      }
      if(item.retail == 1){
        return(
        <span className="fs-7 py-1 px-2" style={{border:'1px solid #4168d1',color:'#4168d1',borderRadius:8,backgroundColor:'white'}}>Retail</span>
        )
      }
    }

  return (
    <>
      <div className='d-flex align-items-center gap-2 gap-lg-3'>
        {showCreateAppModal && (
          <AddCommits
            show={showCreateAppModal}
            propsData={propsData}
            handleClose={() => {
              setShowCreateAppModal(false)
            }}
            changedColor={changedColor}
          />
        )}
        {showCreateAppModal2 && (
          <Details
            show2={showCreateAppModal2}
            propsData2={propsData}
            handleClose2={() => {
              setShowCreateAppModal2(false)
            }}
            setShowCreateAppModalCommit={setShowCreateAppModal}
            showCreateAppModalCommit={showCreateAppModal}
            setShowCreateAppModalTracking={setShowCreateAppModalTracking}
            showCreateAppModalTracking={showCreateAppModalTracking}
            setChangedColor={setChangedColor}
          />
        )}
        {showCreateAppModalTracking && (
          <AddTracking
            show={showCreateAppModalTracking}
            propsData={propsData}
            handleClose={() => {
              setShowCreateAppModalTracking(false)
            }}
            changedColor={changedColor}
          />
        )}
      </div>
      <div>
        <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
        <li className='nav-item'>
            <a
              className='nav-link active'
              style={{cursor: 'pointer'}}
              data-bs-toggle='tab'
              href='#kt_tab_pane_4'
              onClick={() => {
                setLoading(true)
                allActiveData()
                setTimeout(() => {
                  setLoading(false)
                }, 1000)
              }}
            >
              All Deals
            </a>
          </li>
          <li className='nav-item'>
            <a
              className='nav-link'
              style={{cursor: 'pointer'}}
              data-bs-toggle='tab'
              href='#kt_tab_pane_1'
              onClick={() => {
                setLoading(true)
                onRetailData()
                setTimeout(() => {
                  setLoading(false)
                }, 1000)
              }}
            >
              On Retail
            </a>
          </li>
          <li className='nav-item'>
            <a
              className='nav-link'
              style={{cursor: 'pointer'}}
              data-bs-toggle='tab'
              href='#kt_tab_pane_3'
              onClick={() => {
                setLoading(true)
                onCommissionData()
                setTimeout(() => {
                  setLoading(false)
                }, 1000)
              }}
            >
              On Commission
            </a>
          </li>
          <li className='nav-item'>
            <a
              className='nav-link'
              style={{cursor: 'pointer'}}
              data-bs-toggle='tab'
              href='#kt_tab_pane_2'
              onClick={() => {
                setLoading(true)
                belowCostData()
                setTimeout(() => {
                  setLoading(false)
                }, 1000)
              }}
            >
              Below Cost
            </a>
          </li>
          {hotDealExist &&(
          <li className='nav-item'>
            <a
              className='nav-link'
              style={{cursor: 'pointer'}}
              data-bs-toggle='tab'
              href='#kt_tab_pane_5'
              onClick={() => {
                setLoading(true)
                hotDealsData()
                setTimeout(() => {
                  setLoading(false)
                }, 1000)
              }}
            >
              Hot Deals
            </a>
          </li>
          )}
        </ul>
        <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-12'>
            <form>
              <input
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
                type='text'
                className='form-control'
                placeholder='Search...'
              />
            </form>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12'>
            <div style={{textAlign: 'right'}}>
              <Dropdown onSelect={handleSelect}>
                <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                  {selectedFilter ? `Filter: ${selectedFilter}` : 'Select Filter'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey='All' className='text-gray-700'>All</Dropdown.Item>
                  <Dropdown.Item eventKey='In Stock' className='text-gray-700'>In Stock</Dropdown.Item>
                  <Dropdown.Item eventKey='Commitment Full' className='text-gray-700'>Commitment Full</Dropdown.Item>
                  <Dropdown.Item eventKey='Expired' className='text-gray-700'>Expired</Dropdown.Item>
                  {/* <Dropdown.Divider /> */}
                  <Dropdown.Item eventKey='Retail' className='text-gray-700'>Retail</Dropdown.Item>
                  <Dropdown.Item eventKey='Commission' className='text-gray-700'>Commission</Dropdown.Item>
                  <Dropdown.Item eventKey='Below Cost' className='text-gray-700'>Below Cost</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div
            className='col-lg-6 col-md-6 col-sm-12 range_slider d-none'
            style={{position: 'relative'}}
          >
            <div className='thumb-values' style={{left: `${(sliderValues.from / 100000) * 100}%`}}>
              <span className='fw-bold'>${sliderValues.from}</span>
            </div>
            <div
              className='thumb-values'
              style={{left: `${(sliderValues.to / 100000) * 100 - 6}%`}}
            >
              <span className='fw-bold'>${sliderValues.to}</span>
            </div>
            <RangeSlider
              min={0}
              max={100000}
              step={10}
              value={[sliderValues.from, sliderValues.to]}
              onInput={(e) => {
                handleSliderChange(e)
              }}
            />
            <div className='thumb-values2'>
              <span className='fw-bold'>0%</span>
              <span className='fw-bold'>100%</span>
            </div>
          </div>
        </div>

        {/* On Sale Now Data */}
        {loading ? (
          <div className='item' style={{marginTop: '10%'}}>
            <Loader type='spinner-cub' bgColor={'#EA2467'} size={80} />
          </div>
        ) : (
          <div className='row'>
            {filteredData.length === 0 && (
              <div className='text-center mt-10'>
                <h3 className='text-gray-600'>No Deals Found...</h3>
              </div>
            )}
            {filteredData
              .filter((item) => {
                if (item === '') {
                  return item
                } else {
                  return item.name.toLowerCase().includes(searchValue.toLowerCase())
                }
              })
              // .filter((item) => {
              //   return (item.total >= (sliderValues.from) && item.total <= (sliderValues.to));
              // })
              .map((val, index) => {
                return (
                  <div key={index} className='col-sm-6 col-lg-4 col-xl-3 col-md-4'>
                    <div className='card shadow mt-5'>
                    {val.hotdeal == 1 &&(
                    <div class="hot-deal-label" 
                    style={{
                      position: 'absolute',
                      top: '23px',
                      left: '-27px',
                      backgroundColor: '#14255d',
                      color: 'white',
                      padding: '5px 10px',
                      fontWeight: 'bold',
                      borderRadius: '5px',
                      zIndex:5,
                      width:'150px',
                      display:'flex',
                      alignItems:'center',
                      justifyContent:'center',
                      transform: 'rotate(-40deg)', // Rotate by 90 degrees
                      transformOrigin: 'center', // Rotate around the center
                      clipPath: 'polygon(17% 0, 77% 0, 100% 100%, 0 100%)',
                    }}
                    ><span>Hot Deal</span></div>
                    )}
                      <div className='ribbon ribbon-end ribbon-clip' style={{top: '10px'}}>
                        {val.status === 1 ? (
                          <div className='ribbon-label' style={{zIndex:2}}>
                            {val.deal_stock == 'true' ? 'In Stock' : 'Commitment Full'}
                            {val.deal_stock == 'true' ? (
                              <span className='ribbon-inner bg-info'></span>
                            ) : (
                              <span className='ribbon-inner bg-warning'></span>
                            )}
                          </div>
                        ) : (
                          <div className='ribbon-label' style={{zIndex:2}}>
                            Expired
                            <span className='ribbon-inner bg-danger'></span>
                          </div>
                        )}
                      </div>
                      <div className='card-body px-5 py-3'>
                        <div className='shadow card-rounded z-index-1'  style={{position:'relative'}}>
                        <div className="container text-center">
                          <img
                            src={val.default_color && val.default_color?.image}
                            alt='val.name'
                            style={{
                              width: '100%',
                              height: '200px',
                              maxHeight: '450px',
                              objectFit: 'contain',
                              padding:'10px'
                            }}
                          />
                          </div>
                          <div className="container-fluid text-left" style={{position:'absolute',bottom:'10px'}}>
                          {getDealLabel(val)}
                          </div>
                        </div>
                        <div className=''>
                          <div className='d-flex align-items-center' style={{minHeight: '200px'}}>
                            <div className='d-flex align-items-center flex-wrap w-100'>
                              <div className='px-3 flex-grow-1'>
                                <a className='fs-5 text-gray-700 fw-semibold text-hover-primary deal_description' style={{marginLeft:10}}>
                                  {val.name}-{val?.default_color && val.default_color?.color}
                                </a>
                                <div className="container-fluid">
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='fs-4 text-success fw-bold py-2'>
                                    <CurrencyFormat
                                      decimalScale={2}
                                      value={itemPrice(val).orderPrice}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      prefix={'$'}
                                    />
                                  </span>
                                  {itemPrice(val).status === 1 && (
                                    <span className='fs-4 text-muted p-2 text-decoration-line-through'>
                                      <CurrencyFormat
                                        decimalScale={2}
                                        value={itemPrice(val).actualPrice}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                      />
                                    </span>
                                  )}
                                </div>
                                </div>
                                <div className="row" style={{marginLeft:5}}>
                                  <div className="col-lg-6">
                                    <h5 className="fs-6 fw-bold text-gray-800">
                                    <CurrencyFormat
                                          decimalScale={2}
                                          value={itemPrice(val).commission.toFixed(2)}
                                          displayType={'text'}
                                          thousandSeparator={true}
                                          prefix={'$'}
                                        />
                                    </h5>
                                    <h6 className="fs-8 text-gray-600">Commission</h6>
                                  </div>
                                  <div className="col-lg-6">
                                    <h5 className="fs-6 fw-bold  text-gray-800">{formatDate(val.expires)}</h5>
                                    <h6 className="fs-8 text-gray-600">Deal Expires</h6>
                                  </div>
                                </div>
                                <div className="border-bottom my-1"></div>
                                <div className='d-flex flex-column mt-2'>
                                  {/* <div className='text-dark fw-bold'>Stores</div> */}
                                  <div className='d-flex'>
                                    {val.default_color?.stores.map((item, index) => {
                                      return (
                                        <a
                                          key={index}
                                          href={item?.store_url}
                                          target='_blank'
                                          className='symbol symbol-40px me-2 card shadow-xs p-1'
                                          style={{borderRadius:'50%'}}
                                          data-bs-toggle='tooltip'
                                          title={item?.store?.name}
                                        >
                                          <img
                                            src={item?.store?.image}
                                            alt='store'
                                            style={{height: '30px', width: ' 30px'}}
                                          />
                                        </a>
                                      )
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className='d-flex justify-content-around'> */}
                          {/*<button onClick={() => {
                            setShowCreateAppModal(true);
                            setPropsData(val);
                          }} type='button' className='btn btn-info' >Commit </button>*/}

                          {val.status === 1 ? (
                          <div className='d-flex justify-content-around'>
                            {val.deal_stock == 'true' ? (
                              <button
                              onClick={() => {
                                setShowCreateAppModal2(true)
                                setPropsData(val)
                              }}
                              type='button'
                              className='btn btn-warning w-100 mt-2 ml-5'
                              style={{background: 'linear-gradient(123deg, rgba(78,78,252,1) 27%, rgba(129,45,227,1) 50%)'}}
                            >
                              View Deal{' '}
                            </button>
                            ) : (
                              <button
                            onClick={() => {
                              setShowCreateAppModal2(true)
                              setPropsData(val)
                            }}
                            type='button'
                            className='btn btn-light w-100 mt-2 ml-5'
                            style={{border: '2px solid rgba(129,45,227,1)',color:'rgba(129,45,227,1)'}}
                          >
                            Commitment Full{' '}
                          </button>
                            )}
                          </div>
                        ) : (
                          <div className="d-flex justify-content-around">
                          <button
                          onClick={() => {
                            setShowCreateAppModal2(true)
                            setPropsData(val)
                          }}
                          type='button'
                          className='btn btn-light w-100 mt-2 ml-5'
                          // style={{border: '3px solid rgba(240,74,83,1)',color:'rgba(240,74,83,1)'}}
                          style={{border: '2px solid rgba(129,45,227,1)',color:'rgba(129,45,227,1)'}}
                        >
                          Expired{' '}
                        </button>
                        </div>
                        )}
                        
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
        )}
      </div>
    </>
  )
}

export default Deals
