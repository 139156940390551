import React, { useEffect, useState } from 'react';
import { KTIcon } from '../../../_metronic/helpers';
import axios from 'axios';
import Loader from "react-js-loader";
import CurrencyFormat from 'react-currency-format';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';

const getLocalData = () => {
  const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  return JSON.parse(data);
}

const MyOrders = () => {

  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState([]);

  const apiToken = getLocalData().api_token;

  const API_URL = process.env.REACT_APP_API_URL;
  const ORDERS = `${API_URL}/user/orders`;

  const getOrderData = async () => {
    setLoading(true);
    const config = {
      headers: { Authorization: `Bearer ${apiToken}` }
    };
    await axios.get(ORDERS, config)
      .then((response) => {
        setOrderData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getOrderData();
  }, []);

  const statusBody = (item)=>{
    return(
      <>
      {item.status == 0 && (
        <span className='badge badge-light-success fs-8 fw-bold success_active'>Received</span>
      )}
      {item.status == 1 && (
        <span className='badge badge-light-warning fs-8 fw-bold warning_active'>Pending</span>
      )}
      {item.status == 2 && (
        <span className='badge badge-light-success fs-8 fw-bold success_active'>Active</span>
      )}
      {item.status == 3 && (
        <span className='badge badge-light-danger fs-8 danger_active'>Expired</span>
      )}
      {item.status == 4 && (
        <span className='badge badge-light-danger fs-8 fw-bold danger_active'>Not Good</span>
      )}
      {item.status == 5 && (
        <span className='badge badge-light-warning fs-8 fw-bold warning_active'>Return Pending</span>
      )}
      {item.status == 6 && (
        <span className='badge badge-info fs-8 p-3 fw-bold'>Return Active</span>
      )}
      {item.status == 7 && (
        <span className='badge badge-light-success fs-8 fw-bold success_active'>Return Completed</span>
      )}
      </>
    )
  }

  const srnoBody = (item) =>{
    return (
      <span>{orderData.indexOf(item) + 1}</span>
    );
  }

  const commissionBody = (item) => {
    return(
      <span className='text-dark text-hover-primary fs-6'>
        <CurrencyFormat
          decimalScale={2}
          value={parseFloat(item.commission).toFixed(2)}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
        />
      </span>
    )
  };
  
  const priceBody = (item) => {
    return(
      <span className='text-dark text-hover-primary fs-6'>
        <CurrencyFormat
          decimalScale={2}
          value={parseFloat(item.price).toFixed(2)}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
        />
      </span>
    )
  };

  const totalBody = (item) => {
    // const total = item.price * item.qty;
    const total = item.total;
    return(
      <span className='text-dark text-hover-primary fs-6'>
        <CurrencyFormat
          decimalScale={2}
          value={parseFloat(total).toFixed(2)}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
        />
      </span>
    )
  };

  const columnStyle = {
    paddingLeft:10,
    paddingRight:10,
    paddingTop:10,
    paddingBottom:10,
    marginLeft:10,
    marginRight:10,
    width:'200px'
  }

  return (
    <div>
      <h1>My Orders</h1>
      <div className='card shadow'>
        <div className='card-body p-0'>
        <div className="prime-table">
        <DataTable value={orderData} removableSort size="large"
        paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]}
                tableStyle={{ minWidth: '60rem' ,alignItems:'center',marginLeft:'auto', fontSize:15}}
                scrollable scrollHeight="90vh" style={{ minWidth: '50rem' }}
                >

          
          <Column body={srnoBody} header="#" style={columnStyle}></Column>      
          <Column field="order_no" header="Order ID" sortable style={columnStyle}></Column>
          <Column field="tracking_id" header="Tracking No." sortable style={columnStyle}></Column> 
          <Column field="qty" header="Total Qty" style={columnStyle}></Column>
          <Column body={priceBody} header="Total Price" style={columnStyle}></Column>
          <Column body={commissionBody} header="Total Comm" style={columnStyle}></Column>
          <Column body={totalBody} header="Grand Total" style={columnStyle}></Column>  
          <Column body={statusBody} header="Status" style={columnStyle}></Column>         
          <Column field="date" header="Created Date" sortable style={columnStyle}></Column> 
          <Column field="departure_date" header="Modified Date" sortable style={columnStyle}></Column> 

        </DataTable>
        </div>
        
          {/* <div className='table-responsive' style={{display:'none'}}>
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th>S.No</th>
                  <th>Order Id</th>
                  <th>Tracking Number</th> 
                  <th>Status</th> 
                  <th>QTY</th> 
                  <th>Total</th>
                  <th>Created Date</th>  
                  <th>Modified Date</th>
                </tr>

                

              </thead>
              {loading ? (
                <div className="item">
                  <Loader type="spinner-cub" bgColor={"#EA2467"} size={50} />
                </div>
              ) : (
                <tbody>
                  {
                    orderData.map((val, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <span className='text-dark text-hover-primary fs-6'>{index + 1}</span>
                          </td>
                          <td>
                            <span className='text-dark text-hover-primary fs-6'>{val.order_no}</span>
                          </td>
                          <td>
                          <span className='text-dark text-hover-primary fs-6'>{val.tracking_id}</span>
                        </td>
                          <td>
                            {val.status == 0 && (
                              <span className='badge badge-light-success fs-8 fw-bold success_active'>Received</span>
                            )}
                            {val.status == 1 && (
                              <span className='badge badge-light-warning fs-8 fw-bold warning_active'>Pending</span>
                            )}
                            {val.status == 2 && (
                              <span className='badge badge-light-success fs-8 fw-bold success_active'>Active</span>
                            )}
                            {val.status == 3 && (
                              <span className='badge badge-light-danger fs-8 danger_active'>Expired</span>
                            )}
                            {val.status == 4 && (
                              <span className='badge badge-light-warning fs-8 fw-bold warning_active'>In prpgress</span>
                            )}
                            {val.status == 5 && (
                              <span className='badge badge-light-warning fs-8 fw-bold warning_active'>Return Pending</span>
                            )}
                            {val.status == 6 && (
                              <span className='badge badge-info fs-8 p-3 fw-bold'>Return Active</span>
                            )}
                            {val.status == 7 && (
                              <span className='badge badge-light-success fs-8 fw-bold success_active'>Return Completed</span>
                            )}
                          </td> 
                          <td className='text-dark text-hover-primary fs-6'>{val.qty}</td>
                          <td>
                            <span className='text-dark text-hover-primary fs-6'>
                              <CurrencyFormat
                                decimalScale={2}
                                value={parseFloat(val.total).toFixed(2)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                              />
                            </span>
                          </td> 
                          <td>
                            <span className='text-dark text-hover-primary fs-6'>{val.date}</span>
                          </td> 
                          <td>
                            <span className='text-dark text-hover-primary fs-6'>{val.departure_date}</span>
                          </td>
                          
                        </tr>
                      );
                    })
                  }
                </tbody>
              )}
            </table>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default MyOrders;
