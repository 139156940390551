/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Modal } from 'react-bootstrap';
import { KTIcon } from '../../../_metronic/helpers';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CurrencyFormat from 'react-currency-format';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToastMessageSuccess, showToastMessageError , showToastMessageWarning } from '../../modules/Toast-Message/TosatMessage';
import { Send , SyncCircleOutline } from 'react-ionicons'

const API_URL = process.env.REACT_APP_API_URL;
const IMPORT_TRACKING = `${API_URL}/import-tracking-direct`;

const modalsRoot = document.getElementById('root-modals') || document.body;

const AddTracking = ({ show, handleClose, propsData, changedColor }) => {

    const navigate = useNavigate();

    const [commitValue, setCommitValue] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [trackingNumbers, setTrackingNumbers] = useState([]);
    const [textareaValue,setTextareaValue]= useState();
    const [TrackingTextarea,setTrackingTextarea] = useState([]);
    const [MultipleTrackingEnabled,setMultipleTrackingEnabled] = useState(false);

    const [Loading,setLoading] = useState(false);

    const [trackingData, setTrackingData] = useState([
        {
            trackingNumber: '',
            trackingQty: '',
        }
    ]);
    const [trackingErr, setTrackingErr] = useState([
        {
            trackingNumberErr: '',
            trackingQtyErr: '',
        }
    ]);

    const itemPrice = (val) => {
        var data = {};
        if (propsData?.commission_status == 1) {
            const commission = parseFloat(val.commission);
            data = {
                orderPrice: parseFloat(val.price) + commission,
                actualPrice: parseFloat(val.price),
                commission: commission,
                total: parseFloat(val.price) + commission,
            };
        } else if (propsData?.retail == 1) {
            data = {
                orderPrice: parseFloat(val.price),
                actualPrice: parseFloat(val.price),
                commission: 0,
                total: parseFloat(val.price),
            };
        } else if (propsData?.below_cost == 1) {
            data = {
                orderPrice: parseFloat(val.price),
                actualPrice: parseFloat(val.price),
                commission: 0,
                total: parseFloat(val.price),
            };
        };
        return data;
    };

    const addTrackingFields = () => {
        setTrackingData([...trackingData, {
            trackingNumber: '',
            trackingQty: '',
        }]);
        setTrackingErr([...trackingErr, {
            trackingNumberErr: '',
            trackingQtyErr: '',
        }]);
    }

    const removeTrackingFields = (ind) => {
        setTrackingData((prevTrackingData) => {
            const updatedTrackingData = prevTrackingData.filter((_, index) => index !== ind);
            return updatedTrackingData;
        });
        setTrackingErr((prevData) => {
            const updateTrackingErr = prevData.filter((_, index) => index !== ind);
            return updateTrackingErr;
        });
    };

    const handleChangeTrackingNumber = (e, index) => {
        const { value } = e.target;
        setTrackingData((prevTrackingData) => {
            const updatedTrackingData = [...prevTrackingData];
            updatedTrackingData[index] = {
                ...updatedTrackingData[index],
                trackingNumber: value
            };
            setTrackingNumbers([...trackingNumbers, value]);
            return updatedTrackingData;
        });
        setTrackingErr((prevTrackingErr) => {
            const updatedTrackingErr = [...prevTrackingErr];

            if (!updatedTrackingErr[index]) {
                updatedTrackingErr[index] = {
                    trackingNumberErr: '',
                    trackingQtyErr: '', 
                };
            }
            
            updatedTrackingErr[index].trackingNumberErr = value ? '' : 'enter tracking number';
            // updatedTrackingErr[index].trackingNumberErr = /^[A-Za-z]{3}/.test(value) ? '' : 'enter correct tracking number';
            updatedTrackingErr[index].trackingNumberErr = value.toString().length < 4 ? 'enter tracking number with length 4 or more' : '';
            const duplicateExist = trackingNumbers.includes(value);
            updatedTrackingErr[index].trackingNumberErr = duplicateExist ? 'Tracking Already Exist' : '';
            return updatedTrackingErr;
        });
    };

    const handleChangeTrackingQty = (e, index) => {
        const { value } = e.target;
        const newQuantity = value ? parseInt(value.replace(/[^0-9]/g, '')) : 0;
        setTrackingData((prevTrackingData) => {
            const updatedTrackingData = [...prevTrackingData];
            updatedTrackingData[index] = {
                ...updatedTrackingData[index],
                trackingQty: parseInt(value.replace(/[^0-9]/g, '')),
            };
            return updatedTrackingData;
        });

        setTrackingErr((prevTrackingErr) => {
            const updatedTrackingErr = [...prevTrackingErr];
            if (!updatedTrackingErr[index]) {
                updatedTrackingErr[index] = {
                    trackingNumberErr: '',
                    trackingQtyErr: '',
                };
            }
            updatedTrackingErr[index].trackingQtyErr = value ? '' : 'enter quantity';
            return updatedTrackingErr;
        });
    };

    const handleTrackingTextarea = (e)=>{
            setTextareaValue(e.target.value);
            const lines = e.target.value.split('\n').filter(line => line.trim() !== '');
            setTrackingTextarea(lines);
    };

    const handleMultipleTracking = (e)=>{
        setMultipleTrackingEnabled(e.target.checked);
        console.log("check LOg",e.target.checked);
    };

    const saveMultipleTracking = ()=>{
        setTrackingData([]);
        setTrackingErr([]);
        TrackingTextarea.map((value,index)=>{
                setTrackingData((prevTrackingData) => {
                const updatedTrackingData = [...prevTrackingData];
                updatedTrackingData[index] = {
                    ...updatedTrackingData[index],
                    trackingNumber: value,
                };
                setTrackingNumbers([...trackingNumbers, value]);
                return updatedTrackingData;
            });

            setTrackingErr((prevTrackingErr) => {
                const updatedTrackingErr = [...prevTrackingErr];

                if (!updatedTrackingErr[index]) {
                    updatedTrackingErr[index] = {
                        trackingNumberErr: '',
                        trackingQtyErr: '', // Add other error fields if necessary
                    };
                }

                const duplicateExist = trackingNumbers.includes(value);
                updatedTrackingErr[index].trackingNumberErr = duplicateExist ? 'Tracking Already Exist' : '';
                updatedTrackingErr[index].trackingQtyErr = value ? '' : 'Enter quantity';
                return updatedTrackingErr;
            });

        });
        
        setMultipleTrackingEnabled(false);
    }



    const SaveTracking = async (dealId) =>{
        setLoading(true);
        let isValidate = true;

        trackingData.map((val, ind1) => {
            const newTrackingNumber = val.trackingNumber;

            if (val.trackingNumber === '') {
                isValidate = false;
                setTrackingErr((prevTrackingErr) => {
                    const updatedTrackingErr = [...prevTrackingErr];
                    updatedTrackingErr[ind1].trackingNumberErr = 'Enter tracking number';
                    return updatedTrackingErr;
                });
                setLoading(false);
            };
            if (val.trackingQty == '') {
                isValidate = false;
                setTrackingErr((prevTrackingErr) => {
                    const updatedTrackingErr = [...prevTrackingErr];
                    updatedTrackingErr[ind1].trackingQtyErr = 'Qunatity is required!';
                    return updatedTrackingErr;
                });
                setLoading(false);
            };
        });
        
        trackingErr.map((val1) => {
            if (val1.trackingNumberErr !== "") {
                isValidate = false;
                setLoading(false)
            };
            if (val1.trackingQtyErr !== "") {
                isValidate = false;
                setLoading(false)
            };
        });


        if (isValidate) {
            setTrackingErr([{ trackingNumberErr: '', trackingQtyErr: '' }]);
            axios.post(IMPORT_TRACKING, {

                tracking_data: trackingData,
                deal_id: dealId,
                stock_id: changedColor.stock_id ? changedColor.stock_id : propsData.default_color.stock_id,
                commission: changedColor.commission ? changedColor.commission : propsData.default_color.commission,
                price: changedColor.price ? changedColor.price : propsData.default_color.price,
                attribute_code: changedColor.attribute_code ? changedColor.attribute_code : propsData.default_color.attribute_code,

            }).then((response) => {
                // console.log(response);
                    if(response.data.data.status == true)
                    {
                        showToastMessageSuccess(response.data.message);
                        setTrackingData([{ trackingNumber: '', trackingQty: ''}]);
                        setTrackingNumbers([]);
                        setInterval(() => {
                            handleClose();
                        }, 2000);
                        
                    }else{
                        showToastMessageWarning(response.data.message);
                        setLoading(false);
                    }
                    
                })
                .catch((error) => {
                    console.error(error);
                    showToastMessageError(error.message);
                    setLoading(false);
                });
            console.table(trackingData);
            
        };
    }






    const CustomTrackingInput = ()=>{
        return(
            <>
            {trackingData.map((val, index) => {
            return (
                <div key={index}>
                    <div className="form-group title_field">
                        <div className="row">
                            <div className="col-6">
                                <label className="form-label required">Tracking Number</label>
                                <input
                                    value={trackingData[index].trackingNumber}
                                    onChange={(e) => handleChangeTrackingNumber(e, index)}
                                    type="text"
                                    className="form-control"
                                    placeholder='Tracking Number'
                                />
                                {trackingErr[index] && <p className='text-danger position-absolute' style={{ marginTop: "-3px" }}>{trackingErr[index].trackingNumberErr}</p>}
                            </div>
                            <div className="col-4">

                                <label className="form-label required">Quantity</label>
                                <input
                                    value={trackingData[index].trackingQty}
                                    onChange={(e) => handleChangeTrackingQty(e, index)}
                                    type="number"
                                    min={0}
                                    max={5}
                                    className="form-control"
                                    placeholder='Tracking Qty'
                                />
                                {trackingErr[index] && <p className='text-danger position-absolute' style={{ marginTop: "-3px" }}>{trackingErr[index].trackingQtyErr}</p>}
                            </div>
                            <div className="col-2">
                                {index > 0 &&
                                    <div className="col-4 mt-8">
                                        <button onClick={() => removeTrackingFields(index)} className='btn btn-danger px-3 d-flex align-items-center justify-content-center'><i className="fa-solid fa-xmark fa-2x"></i></button>
                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                    <br />
                </div>
            );
        })
    }
</>
)

    }

    const BulkTrackingInput = ()=>{
        return(
            <>
            <div className="form-group">
                <label htmlFor=""></label>
                <textarea name="" id="" value={textareaValue} className="form-control" onChange={(e)=> handleTrackingTextarea(e)} rows="5"  placeholder='Enter Bulk Tracking Number'></textarea>
            </div>
            <div className="form-group mt-2 d-flex align-items-center justify-content-between" >
                <label htmlFor="" className='text-primary'>Save to Store Tracking</label>
                <button className="btn btn-primary btn-sm" type="button" onClick={()=> saveMultipleTracking()}>Save</button>
            </div>
            </>
        )
    }




    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-900px'
            show={show}
            onHide={() => {
                handleClose();
                setTrackingNumbers([]);
                setTrackingData([{ trackingNumber: '', trackingQty: '', }]);
                setTrackingErr([{ trackingNumberErr: '', trackingQtyErr: '' }]);
            }}
            backdrop={true}
        >
            <div className='modal-header pb-0  border-top border-5 border-success'>
                <h2>Add Tracking</h2>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTIcon className='fs-1' iconName='cross' />
                </div>
            </div>
            <ToastContainer />

            <div className='col-sm-12'>
                <div className='card'>
                    <div className='card-body '>
                        <div className="row">
                            <div className="card-rounded text-center col-sm-3">
                                <img src={changedColor.image ? changedColor.image : propsData.default_color?.image} alt='' style={{ width: "200px", height: "200px", objectFit: "contain" }} />
                            </div>
                            <div className='shadow-xs col-sm-9 card-rounded p-5  z-index-1 bg-body' >
                                <div className='d-flex align-items-center'>
                                    <div className='d-flex align-items-center flex-wrap w-100'>
                                        <div className='mb-1 pe-3 flex-grow-1'>
                                            <a href='#' className='fs-5 text-gray-700 text-hover-primary'>
                                                <strong>{propsData.name}-{changedColor.color ? changedColor.color : propsData.default_color?.color}</strong>
                                            </a>
                                            <div className='text-start mt-5'>
                                                <span className="badge badge-success fs-5 text-white fw-bold p-2">   <CurrencyFormat
                                                    decimalScale={2}
                                                    value={itemPrice(changedColor ? changedColor : propsData.default_color).orderPrice.toFixed(2)}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                /></span>

                                            </div>
                                            <div className="">
                                                <div className='d-flex align-items-center justify-content-between my-2 mx-5'>
                                                    <div>
                                                        {/* <div className="form-check form-switch">
                                                            <input className="form-check-input" onChange={(e)=>handleMultipleTracking(e)} checked={MultipleTrackingEnabled} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                            <label className="form-check-label ms-4" for="flexSwitchCheckDefault">Multiple Tracking</label>
                                                        </div> */}
                                                        <div className="form-check form-check">
                                                            <input className="form-check-input border border-info" onChange={(e)=>handleMultipleTracking(e)} checked={MultipleTrackingEnabled} type="checkbox" />
                                                            <label className="form-check-label ms-4 fw-bold" for="flexSwitchCheckDefault">Multiple Tracking</label>
                                                        </div>
                                                    </div>
                                                    {MultipleTrackingEnabled == false && (
                                                        <div><button onClick={addTrackingFields} className='btn btn-primary btn-sm'><i className="fa-solid fa-plus"></i> Add</button></div>
                                                    )}
                                                    
                                                </div>
                                                {MultipleTrackingEnabled == true ? BulkTrackingInput() : CustomTrackingInput() }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
        <div className='d-flex justify-content-between'>
          <div className='' style={{paddingRight: '10px', paddingBottom: '10px'}}>
            <button onClick={handleClose} type='button' className='btn btn-danger  mx-5'>
              Cancel
            </button>
          </div>
          <div className=' ' style={{paddingRight: '10px', paddingBottom: '10px'}}>
            {MultipleTrackingEnabled == false ? (
              <>
              {Loading == true ? (
                <button
                onClick={()=> console.log('loading..')}
                type='button'
                className='btn btn-success fw-bold me-5'
              >
                Saving&nbsp;&nbsp;<SyncCircleOutline color={'white'} height="15px" width="15px" />
              </button>
              ) : (
                <button
                onClick={()=> SaveTracking(propsData.deal_code)}
                type='button'
                className='btn btn-success fw-bold me-5'
              >
                Submit&nbsp;&nbsp;<Send color={'white'} height="15px" width="15px" />
              </button>
              )
              }
              </>
              ) : (
              <>
              <button
                onClick={() => {
                //   commitFun()
                console.log("Submit Disabled Button CLicked");
                
                }}
                type='button'
                className='btn btn-secondary fw-bold me-5'
              >
                Submit&nbsp;&nbsp;<Send color={'white'}  height="15px" width="15px" />
              </button>
              </>
              )
              }
          </div>
          </div>
        </Modal>,
        modalsRoot
    );
}

export { AddTracking };
